@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap4.css";

@import "../node_modules/react-datepicker/dist/react-datepicker.css";

.nav {
  padding-left: 20px;
}

.btn {
  margin: 1px;
}

.form-group.required .col-form-label::after {
  content: " *";
  color: red;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 86%;
}